<template>
  <div v-if="!showLoader" class="">
    <m-page-title title="Sales Orders" />
    <b-row class="d-flex my-3">
      <b-colxx cols="12">
        <b-card>
          <b-row class="align-items-center">
            <b-colxx md="5" sm="12"
              ><b-form
                id="customer-search-main"
                @submit.prevent="getinvoiceList"
              >
                <b-form-group
                  id="search-fieldset"
                  label="Company Name"
                  label-for="search-keyword"
                  class="field-label-form-group"
                >
                  <b-input-group class="">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fas fa-search"></i
                      ></span>
                    </div>
                    <b-form-input
                      id="search-keyword"
                      v-model="searchKeyword"
                      placeholder="Search by company..."
                      type="text"
                    >
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-form>
            </b-colxx>
            <b-colxx md="2" sm="12">
              <b-form-group
                id="search-from-fieldset"
                label="From"
                label-for="search-from-date"
                class="field-label-form-group"
              >
                <b-form-input
                  id="search-from-date"
                  v-model="searchFromDate"
                  type="date"
                />
              </b-form-group>
            </b-colxx>
            <b-colxx md="2" sm="12">
              <b-form-group
                id="search-to-fieldset"
                label="To"
                label-for="search-to-date"
                class="field-label-form-group"
              >
                <b-form-input
                  id="search-to-date"
                  v-model="searchToDate"
                  type="date"
                />
              </b-form-group> </b-colxx
          >
          <b-colxx md="3" sm="12">
              <b-button variant="success" @click="getinvoiceList()"
                >Search</b-button
              >
              <b-button variant="info" @click="clearSearchFilters()"
                >Clear</b-button
              >
            </b-colxx>
          </b-row>
          <!-- <div class="row mt-3 align-items-center">
            
          </div> -->
        </b-card>
      </b-colxx>
    </b-row>

    <div class="my-4">
      <b-table
        id="sales-call-table"
        ref="__salesOrderTable"
        head-variant="dark"
        responsive
        :items="invoiceList"
        :fields="sales_order_list_fields"
        :current-page="current_page"
        :per-page="per_page"
      >
        <template #cell(expand)="row">
          <i
            class="fas icon-pointer"
            :class="
              row.detailsShowing
                ? 'fa-circle-minus text-danger'
                : 'fa-circle-plus text-info'
            "
            @click="row.toggleDetails"
          />
          <i
            v-if="row.item.bquote"
            class="span fas fa-square-quote text-primary ml-2"
            v-b-popover.hover.right="'Quote'"
          />
        </template>
        <template #row-details="row">
          <b-card p-0 m-0>
            <table id="sales-line-item-table" p-0>
              <thead>
                <tr>
                  <th width="50%">Item</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="line in row.item.invoice_lines"
                  :key="line.nlinenum"
                  class="mb-2"
                >
                  <td>{{ line.item_description }}</td>
                  <td>{{ line.dqty }}</td>
                  <td>{{ line.formatted_price }}</td>
                  <td>{{ line.formatted_amount }}</td>
                </tr>
              </tbody>
            </table>
          </b-card>
        </template>
      </b-table>
      <b-pagination
        v-model="current_page"
        :total-rows="total_rows"
        :per-page="per_page"
        :number-of-pages="pageTotal"
        size="sm"
        class="my-0"
        aria-controls="sales-call-table"
        @input="getinvoiceList(current_page)"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import django_session from "@/api/django_session";

  export default {
    name: "InvoiceListHome",
    components: {},

    data() {
      return {
        invoiceList: [],
        // territoryList: [],
        // salesRepList: [],
        searchKeyword: "",
        // selectSalesRep: null,
        // selectCustomerTerritory: null,
        searchFromDate: null,
        searchToDate: null,

        total_rows: null,
        prev_page_link: "",
        next_page_link: "",
        current_page: 1,
        per_page: 0,

        sales_order_list_fields: [
          {
            key: "expand",
            label: "",
          },
          {
            key: "invoice_date",
            label: "Date",
          },
          {
            key: "customer_name",
            label: "Customer",
          },
          {
            key: "invoice_total",
            label: "Total",
          },
          {
            key: "ssource1",
            label: "Inv #",
          },
        ],
      };
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader"]),

      pageTotal() {
        var page_total = 1;
        page_total = Math.ceil(this.total_rows / this.per_page);
        return page_total;
      },
    },

    created() {},

    async mounted() {
      this.$store.dispatch("common/showLoader", true);
      document.title = "Sales Orders";
      await django_session
        .get("sage/invoice-list/", {
          params: {
            page: this.current_page,
            keyword: this.searchKeyword,
            from_date: this.searchFromDate,
            to_date: this.searchToDate,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.invoiceList = response.data.results;
          this.total_rows = response.data.count;
          this.prev_page_link = response.data.links.previous;
          this.next_page_link = response.data.links.next;
          this.current_page = response.data.page;
          this.$store.dispatch("common/showLoader", false);
        });
    },

    methods: {
      async getinvoiceList() {
        this.$store.dispatch("common/showLoader", true);
        await django_session
          .get("sage/invoice-list/", {
            params: {
              page: this.current_page,
              keyword: this.searchKeyword,
              from_date: this.searchFromDate,
              to_date: this.searchToDate,
            },
          })
          .then((response) => {
            console.log(response.data);
            this.invoiceList = response.data.results;
            this.total_rows = response.data.count;
            this.prev_page_link = response.data.links.previous;
            this.next_page_link = response.data.links.next;
            this.current_page = response.data.page;
          });
        this.$store.dispatch("common/showLoader", false);
      },

      clearSearchFilters() {
        this.searchKeyword = "";
        this.searchFromDate = null;
        this.searchToDate = null;
        this.selectSalesRep = null;
        this.selectCustomerTerritory = null;
        this.current_page = 1;
        this.getinvoiceList();
      },
    },
  };
</script>

<style scoped>
  .overflow-y-div {
    overflow-y: scroll;
  }

  .table td th {
    white-space: normal !important;
  }
</style>
